<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <v-row justify="center">
          <ChargeCrud :aplication="aplication" :charges="charges" @setLoading="setLoading" @reactRequest="reactRequest" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChargeCrud from './ChargeCrud.vue';
export default {
  components: { ChargeCrud },
  name: 'ChargesPage',
  props: ['aplication', 'data'],
  data() {
    return {
      charges: null
    }
  },
  methods: {
    setLoading(value){
      this.$emit('setLoading',value);
    },
    reactRequest(value){
      this.$emit('reactRequest',value);
    }
  }
}
</script>