<template>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h2 class="headline font-weight-bold">Extrato Financeiro</h2>
        </v-col>
      </v-row>
        <!-- Saldo -->
      <v-row>
          <v-col cols="12" md="4">
            <v-card class="pa-4" outlined>
            <v-card-title class="subtitle-1">Saldo Atual</v-card-title>
            <v-card-subtitle class="text-h6">{{ balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
                <v-col cols="12" md="5" class="text-right">
                <v-text-field
                    v-model="filters.startDate"
                    label="Data Inicial"
                    type="date"
                    :rules="[validateDate]"
                    outlined
                ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" class="text-right">
                <v-text-field
                    v-model="filters.endDate"
                    label="Data Final"
                    type="date"
                    :rules="[validateDate]"
                    outlined
                ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="text-right">
                <v-btn color="primary" @click="fetchExtract">Filtrar</v-btn>
                </v-col>
            </v-row>
          </v-col>
      </v-row>
      

      <!-- Lançamentos Futuros -->
      <v-row v-if="future.length>0">
        <v-col cols="12">
          <v-data-table
            :headers="futureHeaders"
            :items="future"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Lançamentos Futuros</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Histórico de Movimentações -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="extract"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Histórico de Movimentações</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: ['aplication'],
    name: 'ExtractPage',
    data() {
      return {
        balance: 0,
        extract: [],
        future: [],
        filters: {
          startDate: '',
          endDate: ''
        },
        headers: [
          { text: 'Data', value: 'created_at' },
          { text: 'Tipo', value: 'type' },
          { text: 'Valor', value: 'value' },
          { text: 'Fonte', value: 'source.type' },
          { text: 'Status', value: 'source.status' }
        ],
        futureHeaders: [
          { text: 'Data Prevista', value: 'created_at' },
          { text: 'Tipo', value: 'type' },
          { text: 'Valor', value: 'value' }
        ]
      };
    },
    methods: {
      validateDate(value) {
        if (!value) return 'Data é obrigatória';
        if (this.filters.startDate && this.filters.endDate) {
          const start = new Date(this.filters.startDate);
          const end = new Date(this.filters.endDate);
          const diffInMs = end - start;
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          if (diffInDays > 90) {
            return 'Período não pode ser superior a 3 meses';
          }
        }
        return true;
      },
      async fetchExtract() {
        try {
          this.$emit('setLoading',true);
          const response = await axios.get(this.aplication.baseUrlApi +'/app/extract', {
            headers: { Authorization: this.aplication.authToken },
            params: {
              startDate: this.filters.startDate,
              endDate: this.filters.endDate
            }
          });
          this.$emit("reactRequest", response.data);
          this.$emit('setLoading',false);
          const data = response.data;
          this.extract = data.extract;
          this.future = data.future;
          this.balance = data.balance || 0;
        } catch (error) {
          this.$emit('setLoading',false);
          if (error.response &&error.response.data &&error.response.data.errors) {
            this.$emit("reactRequest", error.response.data);
          }
        }
      }
    },
    mounted() {
      this.fetchExtract();
    }
  };
  </script>
  
  <style scoped>
  .headline {
    margin-bottom: 1rem;
  }

  </style>  