<template>
    <div>
        <v-dialog v-model="form.dialog" max-width="500px">
          <v-card>
            <v-card-title>
                <span class="text-h5">{{ form.isEditing ? 'Atualizar Cobrança' : 'Nova Cobrança' }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="form.isValid">
                  <v-text-field
                      v-if="!client_id && !form.isEditing"
                      v-model="form.charge.customer_id"
                      label="ID do Cliente"
                      type="number"
                      :rules="[form.rules.required]"
                      required
                  ></v-text-field>

                  <v-select
                      v-model="form.charge.billing_type"
                      :items="form.paymentMethods"
                      label="Método de pagamento"
                      :rules="[form.rules.required]"
                      required
                  ></v-select>

                  <v-text-field
                      v-model="form.charge.duedate"
                      label="Data de Vencimento"
                      type="date"
                      :rules="[form.rules.required]"
                      required
                  ></v-text-field>

                  <v-text-field
                      v-model="form.charge.value"
                      label="Valor"
                      prefix="R$"
                      type="number"
                  ></v-text-field>

                  <v-textarea
                      v-model="form.charge.description"
                      label="Descrição"
                      :rules="[form.rules.required]"
                      required
                  ></v-textarea>

                  <template>
                    <div v-if="form.charge.split.length>0">
                      <div class="subtitle-2">Comissionamento</div>
                      <div v-for="(split,splitKey) in form.charge.split" :key="splitKey" class="elevation-3 pa-2 mb-2">
                        <v-layout wrap>
                          <v-flex>
                            {{ split.ref }}
                            <div v-if="split.value">Valor: R$ {{ split.value }}</div>
                            <div v-if="split.percentage">Percentual:{{ split.percentage }}%</div>
                          </v-flex>
                          <v-flex shrink>
                            <v-btn icon color="error" @click="form.charge.split.splice(splitKey,1)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                    <div v-if="form.split.open" class="elevation-3 pa-2">
                      <v-text-field
                        v-model="form.split.ref"
                        label="Referência do Usuário da Plataforma"
                      ></v-text-field>
                      <v-select
                        v-model="form.split.field"
                        :items="form.split.fieldOptions"
                        label="Tipo da referência"
                      ></v-select>
                      <v-text-field
                          v-model="form.split.value"
                          label="Valor Fixo para Comissão"
                          prefix="R$"
                          type="number"
                      ></v-text-field>
                      <v-text-field
                          v-model="form.split.percentage"
                          label="Percentual para Comissão"
                          prefix="%"
                          type="number"
                      ></v-text-field>

                      <v-btn small color="error" outlined @click="closeSplit()">Cancelar</v-btn>
                      <v-btn small color="primary ml-2" outlined @click="addSplit()">Aplicar comissionamento</v-btn>
                    </div>
                    <v-btn v-else small color="secondary" outlined @click="form.split.open = !form.split.open,form.split.value=0,form.split.percentage=0,form.split.ref=''">Aplicar comissionamento</v-btn>
                  </template>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="form.dialog = !form.dialog">Cancelar</v-btn>
                <v-btn color="green" text @click="saveCharge" :disabled="!form.isValid">
                {{ isEditing ? 'Atualizar' : 'Salvar' }}
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card class="mb-4">
          <v-card-title>
            <span class="headline">Cobranças</span>
            <v-spacer></v-spacer>
            <v-btn v-if="client_id" color="primary" @click="addNewCharge">Adicionar Nova Cobrança</v-btn>
          </v-card-title>
          <v-data-table :items="list" :headers="chargeHeaders" hide-default-footer>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="editCharge(item)">mdi-pencil</v-icon>
              <v-menu open-on-hover top offset-y v-if="item.payment_link">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="secondary" dark v-bind="attrs" v-on="on" icon><v-icon small>mdi-link-variant</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="copyToClipBoard(item.payment_link)">
                    <v-list-item-title>Copiar Link</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <a :href="item.payment_link" target="_blank">Abrir Link</a>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip :color="statusLabel[item.status].color" small>
                {{ statusLabel[item.status].label }}
              </v-chip>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination v-model="page" :length="totalPages" @input="fetchCharges"></v-pagination>
          </div>
        </v-card>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: ['aplication', 'charges', 'client_id'],
    data() {
      return {
        chargeValue: '',
        isEditing: null,
        chargeHeaders: [
          { text: "Data de Vencimento", value: "duedate" },
          { text: "Descrição", value: "description" },
          { text: "Valor", value: "value" },
          { text: "Status", value: "status" },
          { text: "Ações", value: "actions", sortable: false },
        ],
        statusLabel:{
          removed: {label: "Removida", color: "red"},
          processing: {label: "Em processamento", color: "blue"},
          failed: {label: "Falha na tentativa de pagamento", color: "red darken-2"},
          refunded: {label: "Estornada", color: "green"},
          refund_processing: {label: "Estorno em processamento", color: "blue lighten-2"},
          chargeback_concluded: {label: "Chargeback concluído", color: "orange"},
          chargeback_processing: {label: "Chargeback em processamento", color: "orange lighten-2"},
          chargeback_received: {label: "Chargeback recebido", color: "orange darken-1"},
          expired: {label: "Vencida", color: "grey"},
          waiting_payment: {label: "Aguardando pagamento", color: "orange"},
          processing_card_payment: {label: "Processando pagamento em cartão", color: "blue"},
          payment_confirmed: {label: "Pagamento confirmado", color: "green"},
          payment_received: {label: "Pagamento recebido", color: "green darken-1"},
          received_directly: {label: "Recebido diretamente", color: "green accent-4"},
          payment_under_review: {label: "Pagamento em análise", color: "blue darken-1"}
        },
        list: [],
        totalPages: 1,
        page: 1,
        client_idd: null,
        form:{
            dialog: false,
            isValid: false,
            isEditing: null,
            split: {
              open: false,
              value: 0,
              percentage: 0,
              ref: '',
              field: 'email',
              fieldOptions:[
                {text: 'E-mail',value:'email'},
                {text: 'ID',value:'id'},
                {text: 'CPF/CNPJ',value:'document'}
              ]
            },
            charge: {
                id: null,
                customer_id: '',
                billing_type: 'undefined',
                duedate: '',
                value: '',
                description: '',
                split: []
            },
            paymentMethods:[
              {value:"creditcard", text:"Cartão de Crédito"},
              {value:"barcode", text:"Boleto"},
              {value:"pix", text:"Pix"},
              {value:"undefined",text:"Pergunte ao cliente"}
            ],
            rules: {
                required: value => !!value || 'Campo obrigatório'
            }
        }
      };
    },
    methods: {
      async post(endpoint = "/", data) {
      try {
        this.$emit('setLoading',true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "POST",
          headers: {
            'Authorization': this.aplication.authToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        });
        this.$emit('reactRequest',response.data);
        this.$emit('setLoading',false);
        return response.data;
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest',error.response.data);
        }
      }
        },
      async get(endpoint = "/") {
        try {
          this.$emit('setLoading',true);
          const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "GET",
          headers: {
              'Authorization': this.aplication.authToken,
          }
          });
          this.$emit('reactRequest',response.data);
          this.$emit('setLoading',false);
          return response.data;
        } catch (error) {
          this.$emit('setLoading',false);
          if (error.response && error.response.data && error.response.data.errors) {
            this.$emit('reactRequest',error.response.data);
          }
          return null;
        }
      },
      async fetchCharges() {
        let response;
        if(this.client_idd)
        response = await this.get(`/client/${this.client_idd}/charges?page=${this.page}&getCustomer=true`);
        else
        response = await this.get(`/charges?page=${this.page}&getCustomer=true`);
        if(!response) return;
        if(response.items){
          this.list = response.items;
          this.totalPages = response.pages;
        }
      },
      addNewCharge(){
        if(this.client_id){
          this.form.charge.customer_id = this.client_id;
        }
        this.form.charge.id = null;
        this.form.charge.billing_type = 'undefined';
        this.form.charge.duedate = '';
        this.form.charge.value = '';
        this.form.charge.description = '';
        this.form.charge.split = [];
        this.form.isEditing = false;
        this.form.dialog = true;
      },
      editCharge(item){
        this.form.charge.id = item.id;
        this.form.charge.customer_id = item.client_id;
        this.form.charge.billing_type = item.billing_type;
        this.form.charge.duedate = item.duedate;
        this.form.charge.value = item.value;
        this.form.charge.description = item.description;
        this.form.isEditing = true;
        this.form.dialog = true;
      },
      async saveCharge() {
        if (!this.$refs.form.validate()) {
            alert("Verifique os dados do formulário");
            return;
        }

        let endpoint = "/charge";
        if(this.form.isEditing === true) 
          endpoint = endpoint + "/"+this.form.charge.id+"/update";
        
        let response = await this.post(endpoint,this.form.charge);
        if(response.id){
          if(this.form.isEditing === false)
            this.list.unshift(response);
          else{
            for(let i=0;i<this.list.length;i++)
              if(this.list[i].id==response.id)
              {
                this.$set(this.list,i, response);
              }
          }
          this.form.dialog = false;
          alert("Cobrança salva");
        }
      },
      copyToClipBoard(text,msg="Copiado para área de transferência") {
          navigator.clipboard.writeText(text).then(() => {
            alert(msg);
          });
      },
      closeSplit(){
        this.form.split.open = false;
      },
      async addSplit(){
        if(!this.form.split.value && !this.form.split.percentage){
          alert('Informe um valor ou um percentual para comissão');
          return;
        }
        let wallet;
        wallet = await this.get(`/app/users/wallet/${this.form.split.ref}/${this.form.split.field}`);
        console.log(wallet);
        if(!wallet){
          return;
        }
        let split = {
          wallet_id: wallet.wallet_id,
          ref: this.form.split.ref
        };
        if(this.form.split.value){
          split.value = this.form.split.value;
        }
        if(this.form.split.percentage){
          split.percentage = this.form.split.percentage;
        }
        this.form.charge.split.push(split);
        this.closeSplit()
      }
    },
    mounted(){
        if(this.client_id){
            this.client_idd = this.client_id;
            this.list = (this.charges.items ?? []);
            this.totalPages = this.charges.pages;
        }else{
            this.chargeHeaders.unshift({ text: "Cliente", value: "customer_name" })
            this.fetchCharges();
        }
    }
  };
  </script>
  