<template>
  <v-container>
    <v-card>
      <!-- Expansion Panels -->
      <v-expansion-panels>
        <!-- Informações Pessoais -->
        <v-expansion-panel>
          <v-expansion-panel-header>Informações Pessoais</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-title>Atualize suas informações pessoais</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="updateUserInfo">
                  <v-text-field label="Nome" v-model="user.name"></v-text-field>
                  <v-text-field label="Sobrenome" v-model="user.lastname"></v-text-field>
                  <v-text-field label="Data de Nascimento" v-model="user.birthday"></v-text-field>
                  <v-text-field label="Telefone" v-model="user.cellphone_number"></v-text-field>
                  <v-text-field label="Email" v-model="user.email" disabled></v-text-field>
                  <v-text-field label="CPF ou CNPJ" v-model="user.document" disabled></v-text-field>
                  <v-btn type="submit" color="primary">Salvar</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Alterar Senha -->
        <v-expansion-panel>
          <v-expansion-panel-header>Alterar Senha</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-title>Alterar Senha</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="updatePassword">
                  <v-text-field label="Senha Atual" v-model="passwords.current" type="password"></v-text-field>
                  <v-text-field label="Nova Senha" v-model="passwords.new" type="password"></v-text-field>
                  <v-text-field label="Confirmar Nova Senha" v-model="passwords.confirm" type="password"></v-text-field>
                  <v-btn type="submit" color="primary">Alterar Senha</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Dados Bancários -->
        <v-expansion-panel>
          <v-expansion-panel-header>Dados Bancários</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card class="mb-2" v-if="bank && bank.length > 0">
              <v-card-title>Dados Bancários</v-card-title>
              <v-card-text v-for="(bankItem, index) in bank" :key="index">
                <div v-if="bankItem.type === 'pix'">
                  <strong>Tipo:</strong> PIX<br />
                  <strong>Código:</strong> {{ bankItem.pixcode }}<br />
                  <strong>Tipo de PIX:</strong> {{ bankItem.pixtype === 'phone' ? 'Telefone' : bankItem.pixtype }}
                </div>
                <div v-else-if="bankItem.type === 'ted'">
                  <strong>Tipo:</strong> TED<br />
                  <strong>Banco:</strong> {{ bankItem.bank }}<br />
                  <strong>Agência:</strong> {{ bankItem.ag }}<br />
                  <strong>Conta Corrente:</strong> {{ bankItem.cc }}<br />
                  <strong>Tipo de Conta:</strong> {{ bankItem.accountType === 'savings' ? 'Poupança' : 'Corrente' }}
                </div>
                <v-divider v-if="index < bank.length - 1" class="my-2"></v-divider>
              </v-card-text>
            </v-card>

            <v-card flat v-if="!bank || bank.length == 0 || addNewBank">
              <v-card-title>Adicionar conta bancária</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="saveBankInfo">
                  <v-text-field label="CPF ou CNPJ do titular" v-model="user.document" readonly hint="Somente é possível cadastrar uma conta da qual você é o titular"></v-text-field>
                  <v-text-field label="Banco" v-model="bankInfo.bankNumber" type="number"></v-text-field>
                  <v-text-field label="Agência (sem DV)" v-model="bankInfo.agency" type="number"></v-text-field>
                  <v-text-field label="Número da Conta (com DV)" v-model="bankInfo.accountNumber" type="number"></v-text-field>
                  <v-select label="Tipo da Conta" v-model="bankInfo.accountType" :items="[{text: 'Conta Corrente', value: 'checking'}, {text: 'Conta Poupança', value: 'savings'}]"></v-select>
                  <v-btn type="submit" color="primary">Adicionar conta</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
            <v-card flat v-else>
              <v-card-text class="text-right">
                <v-btn color="secondary" outlined @click="addNewBank=true">Adicionar nova conta</v-btn>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Integração (WalletId e ApiKey) -->
        <v-expansion-panel>
          <v-expansion-panel-header>Integração</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-title>Wallet ID</v-card-title>
              <v-card-text>
                <div class="border border-1">
                  <v-text-field
                    :append-icon="'mdi-content-copy'"
                    @click:append="copyApiKey"
                    v-model="user.wallet_id"
                    readonly
                    single-line
                    outlined
                  ></v-text-field>
                </div>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-title>Documentação da API</v-card-title>
              <v-card-text class="text-right">
                <v-btn color="primary" outlined :href="apiDocUrl" target="_blank">Acessar a Documentação da API</v-btn>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-title>Gerar Chave de API</v-card-title>
              <v-card-text class="text-right">
                <v-btn @click="generateApiKey" color="primary" outlined>Gerar Nova Chave</v-btn>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Webhooks -->
        <v-expansion-panel>
          <v-expansion-panel-header>Webhooks</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-title>Configurar Webhooks</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="saveWebhookConfig">
                  <v-text-field label="URL do Webhook" v-model="webhookConfig.url"></v-text-field>
                  <v-select label="Eventos do Webhook" :items="webhookEvents" v-model="webhookConfig.events" multiple></v-select>
                  <v-btn type="submit" color="primary">Salvar</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    name: "UserAccount",
    props: ["aplication", "data"],
    data() {
        return {
        apiDocUrl: window.API_DOC_URL,
        activeTab: 0,
        user: {
            name: "",
            lastname: "",
            email: "",
            birthday: "",
            document: "",
        },
        passwords: {
            current: "",
            new: "",
            confirm: "",
        },
        apiKey: "",
        bank: [],
        bankInfo: {
            bankNumber: "",
            agency: "",
            accountNumber: "",
            accountType: ""
        },
        addNewBank: false,
        webhookConfig: {
            url: "",
            events: [],
        },
        webhookEvents: ["Cadastro", "Atualização", "Remoção"], // Exemplos
        };
    },
    methods: {
        async post(endpoint = "/", data) {
        try {
          this.$emit('setLoading',true);
          const response = await axios({
            url: this.aplication.baseUrlApi + endpoint,
            method: "POST",
            headers: {
                Authorization: this.aplication.authToken,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          });
          this.$emit('setLoading',false);
          this.$emit("reactRequest", response.data);
          return response.data;
        } catch (error) {
          this.$emit('setLoading',false);
          if (error.response &&error.response.data &&error.response.data.errors) {
            this.$emit("reactRequest", error.response.data);
          }
        }
        },
        async get(endpoint = "/") {
        try {
          this.$emit('setLoading',true);
          const response = await axios({
            url: this.aplication.baseUrlApi + endpoint,
            method: "GET",
            headers: {
                Authorization: this.aplication.authToken,
            },
          });
          this.$emit("reactRequest", response.data);
          this.$emit('setLoading',false);
          return response.data;
        } catch (error) {
          this.$emit('setLoading',false);
          if (error.response &&error.response.data &&error.response.data.errors) {
            this.$emit("reactRequest", error.response.data);
          }
        }
        },
        async updateUserInfo() {
          // Implementar lógica de atualização de informações pessoais
          let response = await this.post("/app/account/update",this.user);
          console.log(response);
        },
        async updatePassword() {
          let response = await this.post("/app/account/passwd/update",this.passwords);
          if(response.status){
            this.passwords.new = "";
            this.passwords.current = "";
            this.passwords.confirm = "";
          }
        },
        async generateApiKey() {
        let response = await this.get("/app/account/generate/apikey");
          console.log(response);
        },
        copyApiKey() {
          //if(this.user.wallet_id)
          navigator.clipboard.writeText(this.user.wallet_id).then(() => {
            alert("Chave copiada para a área de transferência!");
          });
        },
        async saveBankInfo() {
          let response = await this.post("/app/account/bank",this.bankInfo);
          if(response.bank){
            this.bank = response.bank;
            this.bankInfo.bankNumber = "";
            this.bankInfo.agency = "";
            this.bankInfo.accountNumber = "";
            this.bankInfo.accountType = "";
            this.addNewBank = false;
          }
        },
        async saveWebhookConfig() {
          // Implementar lógica para salvar configuração de webhook
          let response = await this.post("/app/account/webhook",this.webhookConfig);
          console.log(response);
        },
        async fetchUserData(){
          let response = await this.get("/app/user/data");
          if(response.user)
            this.user = response.user;
          if(response.bank)
            this.bank = response.bank;
        }
    },
    mounted() {
        // Inicialização e chamadas à API para carregar os dados do usuário, caso necessário
        this.fetchUserData();
    },
  };
</script>
  
  <style scoped>
.v-card {
  max-width: 600px;
  margin: auto;
}
</style>  