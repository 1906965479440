<template>
    <v-container>

      <v-dialog v-model="showSchoolForm" max-width="500px">
        <v-card>
            <v-card-title class="headline">Configurar Portal de Ensino</v-card-title>
            <v-card-text>
            <v-form ref="schoolForm">
                <v-text-field v-model="schoolData.uri" label="URL" required></v-text-field>
                <v-text-field v-model="schoolData.name" label="Nome" required></v-text-field>
                <v-text-field v-model="schoolData.description" label="Descrição"></v-text-field>
                <v-text-field v-model="schoolData.support_email" label="Email de Suporte" required></v-text-field>
                <v-text-field v-model="schoolData.support_phone" label="Telefone de Suporte"></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="saveSchool">{{ schoolData.school_id ? "Atualizar" : "Cadastrar" }}</v-btn>
            <v-btn text @click="showSchoolForm = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showCourseForm" max-width="500px">
        <v-card>
            <v-card-title class="headline">Gerenciar Curso</v-card-title>
            <v-card-text>
            <v-form ref="courseForm">
                <v-text-field v-model="courseData.name" label="Nome do Curso" required></v-text-field>
                <v-text-field v-model="courseData.description" label="Descrição"></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="saveCourse">Salvar</v-btn>
            <v-btn text @click="showCourseForm = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showModuleForm" max-width="500px">
        <v-card>
            <v-card-title class="headline">Gerenciar Módulo</v-card-title>
            <v-card-text>
            <v-form ref="moduleForm">
                <v-text-field v-model="moduleData.name" label="Nome do Módulo" required></v-text-field>
                <v-text-field v-model="moduleData.description" label="Descrição"></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="saveModule">Salvar</v-btn>
            <v-btn text @click="showModuleForm = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showLessonForm" max-width="500px">
        <v-card>
            <v-card-title class="headline">Gerenciar Aula</v-card-title>
            <v-card-text>
            <v-form ref="lessonForm">
                <v-text-field v-model="lessonData.name" label="Nome da Aula" required></v-text-field>
                <v-text-field v-model="lessonData.description" label="Descrição"></v-text-field>
                <v-text-field v-model="lessonData.embed" label="Código Embed (ex.: URL do vídeo)"></v-text-field>
                <v-textarea v-model="lessonData.content" label="Conteúdo Adicional"></v-textarea>
            </v-form>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="saveLesson">Salvar</v-btn>
            <v-btn text @click="showLessonForm = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

  
      <div v-if="!school">
        <v-btn color="primary" @click="showDialogSchoolForm(null)">Configurar Portal de Ensino</v-btn>
      </div>
  
      <div v-else>
        <template v-if="!currentCourse">
            <h3>Portal: {{ school.name }}</h3>
            <p>{{ school.description }}</p>
            <p>Email de Suporte: {{ school.support.email }}</p>
            <p>Telefone de Suporte: {{ school.support.phone }}</p>
            <v-btn color="secondary" @click="showDialogSchoolForm(school.id)" outlined>Editar Portal</v-btn>
            <v-btn color="secondary" @click="fetchComments" outlined>Moderar Comentários</v-btn>
    
            <!-- Seção de Cursos -->
            <v-row>
            <v-col cols="12">
                <h3>Cursos</h3>
                <v-btn :color="((courses.length>0) ? 'secondary' : 'primary')" @click="showCourseForm = true" :outlined="(courses.length>0)">Adicionar Curso</v-btn>
                <v-row>
                <v-col v-for="course in courses" :key="course.id" cols="12" md="6">
                    <v-card @click="fetchModules(course.id)">
                    <v-card-title>{{ course.name }}</v-card-title>
                    <v-card-text>{{ course.description }}</v-card-text>
                    <v-card-actions>
                        <v-btn icon @click.stop="editCourse(course)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click.stop="deleteCourse(course.id)">
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-col>
                </v-row>
            </v-col>
            </v-row>
        </template>
        <template v-else>
            <!-- Moderação de Comentários -->
            <div v-if="showComments">
                <h3>Moderação de Comentários</h3>
                <v-row>
                <v-col v-for="comment in comments" :key="comment.id" cols="12">
                    <v-card>
                    <v-card-text>{{ comment.content }}</v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="approveComment(comment.id)">Aprovar</v-btn>
                        <v-btn color="error" @click="rejectComment(comment.id)">Reprovar</v-btn>
                        <v-btn color="info" @click="replyToComment(comment.id)">Responder</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-col>
                </v-row>
            </div>
        
            <!-- Seção de Módulos e Aulas -->
            <div v-if="currentCourse">
                <h3>
                    <v-btn icon @click="closeCourse" color="error" flat><v-icon>mdi-close-circle</v-icon></v-btn>
                    {{ currentCourse.name }}
                </h3>
                <p>{{ currentCourse.description }}</p>
                <v-btn :color="((modules && modules.length>0) ? 'secondary' : 'primary')" :outlined="((modules && modules.length>0) ? true : false)" @click="showModuleForm = true">Adicionar Módulo</v-btn>
                <v-row>
                <v-col v-for="module in modules" :key="module.id" cols="12">
                    <v-card>
                    <v-card-title>{{ module.name }}</v-card-title>
                    <v-card-text>
                      {{ module.description }}
                      <div class="text-right">
                        <v-btn icon @click="editModule(module)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="deleteModule(module.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn :color="((module.lessons && module.lessons.length>0) ? 'secondary' : 'primary')" :outlined="((module.lessons && module.lessons.length>0) ? true : false)" @click="showDialogLessonForm(module.id,null)">Adicionar Aula</v-btn>
                      </div>
                    </v-card-text>
                    <v-data-table v-if="module.lessons && module.lessons.length>0" :items="module.lessons" class="mt-4" :headers="lessonsHeaders" hide-default-footer>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon @click="showDialogLessonForm(module.id,item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="deleteLesson(item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    </v-card>
                </v-col>
                </v-row>
            </div>
        </template>
      </div>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: ['aplication'],
    data() {
      return {
        school: null,
        courses: [],
        modules: [],
        comments: [],
        currentCourse: null,
        showSchoolForm: false,
        showCourseForm: false,
        showModuleForm: false,
        showLessonForm: false,
        showComments: false,
        currentModule: {id: null,key:null},
        schoolData: { school_id: null, uri: '', name: '', description: '', support_email: '', support_phone: '' },
        courseData: { name: '', description: '' },
        moduleData: { name: '', description: '' },
        lessonData: { lesson_id: null, name: '', description: '', embed: '', content: '' },
        lessonsHeaders:[
          {text: 'Nome', value:'name', sortable: false},
          {text: 'Descrição', value:'description', sortable: false},
          {text: 'Ações', value:'actions', sortable: false}
        ]
      };
    },
    methods: {
      async post(endpoint = "/", data) {
          try {
            this.$emit('setLoading', true);
            const response = await axios.post(this.aplication.baseUrlApi + endpoint, data, {
              headers: { Authorization: this.aplication.authToken, 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            this.$emit('setLoading',false);
            this.$emit('reactRequest', response.data);
            return response.data;
          } catch (error) {
            this.$emit('setLoading',false);
            if (error.response && error.response.data && error.response.data.errors) {
              this.$emit('reactRequest',error.response.data);
            }
          }
      },
      async get(endpoint = "/") {
          try {
            this.$emit('setLoading',true);
            const response = await axios({
              url: this.aplication.baseUrlApi + endpoint,
              method: "GET",
              headers: {
                'Authorization': this.aplication.authToken,
              }
            });
            this.$emit('reactRequest',response.data);
            this.$emit('setLoading',false);
            return response.data;
          } catch (error) {
            this.$emit('setLoading',false);
            if (error.response && error.response.data && error.response.data.errors) {
                this.$emit('reactRequest',error.response.data);
            }
          }
      },
      async fetchSchool() {
        const response = await this.get('/members/school');
        this.school = response ? response.school : null;
        if (this.school)
          if (this.courses) this.courses = (response.courses ?? []);
          //else this.fetchCourses();
      },
      showDialogSchoolForm(school_id=false){
        if(school_id){
            this.schoolData.school_id = school_id;
            this.schoolData.uri = this.school.uri;
            this.schoolData.name = this.school.name;
            this.schoolData.description = this.school.description;
            this.schoolData.support_email = this.school.support.email;
            this.schoolData.support_phone = this.school.support.phone;
        }else{
            this.schoolData.school_id = null;
            this.schoolData.uri = '';
            this.schoolData.name = '';
            this.schoolData.description = '';
            this.schoolData.support_email = '';
            this.schoolData.support_phone = '';
        }
        this.showSchoolForm = true;
      },
      async showDialogLessonForm(module_id,lesson=false){
        this.currentModule.id = module_id;
        //this.lessonData.module_id = module_id;
        if(lesson){
            let response = await this.get("/members/lesson/"+lesson.id);
            if(!response || !response.lesson){
                if(!response.alert && !response.message && !response.errors)
                  alert("Erro em obter os dados da Aula");
                return;
            }
            this.lessonData.lesson_id = response.lesson.id;
            this.lessonData.name = response.lesson.name;
            this.lessonData.description = response.lesson.description;
            this.lessonData.embed = response.lesson.embed;
            this.lessonData.content = response.lesson.content;
        }else{
            this.lessonData.lesson_id = null;
            this.lessonData.name = '';
            this.lessonData.description = '';
            this.lessonData.embed = '';
            this.lessonData.content = '';
        }
        this.showLessonForm = true;
      },
      async fetchCourses() {
        let response = await this.get('/members/courses');
        this.courses = response ? response.courses : [];
      },
      async fetchModules(courseId) {
        this.currentCourse = this.courses.find(course => course.id === courseId);
        let response = await this.get(`/members/course/${courseId}/modules`);
        this.modules = response ? response.modules : [];
      },
      async closeCourse() {
        this.currentCourse = null;
        this.modules = [];
      },
      async fetchComments() {
        this.showComments = true;
        let response = await this.get('/members/comments');
        this.comments = response ? response.comments : [];
      },
      async approveComment(commentId) {
        await this.post(`/members/comments/${commentId}/approve`);
        this.fetchComments();
      },
      async rejectComment(commentId) {
        await this.post(`/members/comments/${commentId}/reject`);
        this.fetchComments();
      },
      async deleteCourse(courseId) {
        await this.post(`/members/course/${courseId}/delete`);
        this.fetchCourses();
      },
      async deleteModule(moduleId) {
        await this.post(`/members/modules/${moduleId}/delete`);
        this.fetchModules(this.currentCourse.id);
      },
      async deleteLesson(lessonId) {
        await this.post(`/members/lessons/${lessonId}/delete`);
        this.fetchModules(this.currentCourse.id);
      },
      async saveSchool() {
        let response = await this.post('/members/school', this.schoolData);
        if(response.school){
            this.school = response.school;
            this.showSchoolForm = false;
        }
      },
      async saveCourse() {
        let response = await this.post('/members/course', this.courseData);
        if(response.course){
          if(!this.courseData.course_id){
            this.courses.push(response.course);
            this.course = response.course;
          }
          this.showCourseForm = false;
        }
      },
      async saveModule() {
        let response = await this.post(`/members/course/${this.currentCourse.id}/module`, this.moduleData);
        //this.fetchModules(this.currentCourse.id); // Atualiza módulos
        if(response.module){
            this.module=response.module;
            this.modules.push(response.module);
            this.showModuleForm = false;
        }
      },
      async saveLesson() {
        let response;
        if(this.lessonData.lesson_id)
          response = await this.post(`/members/course/${this.currentCourse.id}/module/${this.currentModule.id}/lesson/update`, this.lessonData);
        else
          response = await this.post(`/members/course/${this.currentCourse.id}/module/${this.currentModule.id}/lesson`, this.lessonData);
        
        if(response.lesson){
          this.showLessonForm = false;
          for(let i=0;i<this.modules.length;i++){
            //console.log("Modulo: "+this.modules[i].id);
            if(this.modules[i].id==response.lesson.module_id)
              if(this.lessonData.lesson_id)
                for(let j=0;j<this.modules[i].lessons.length;j++){
                  //console.log("Aula: "+this.modules[i].lessons[j].id);
                  if(this.modules[i].lessons[j].id == response.lesson.id){
                      //this.modules[i].lessons[j] == response.lesson;
                      this.$set(this.modules[i].lessons, j, response.lesson);
                      return;
                  }
                }
              else{
                this.modules[i].lessons.push(response.lesson);
                return;
              }
          }
          /*
          {
            if(this.lessonData.lesson_id)
              for(let j=0;j<this.modules[i].lessons.length;j++)
                if(this.modules[i].lessons[j].id == response.lesson.id){
                    //this.modules[i].lessons[j] == response.lesson;
                    this.$set(this.modules[i].lessons, j, response.lesson);
                    return;
                }
            else
              if(this.modules[i].id==response.lesson.module_id){
                this.modules[i].lessons.push(response.lesson);
                return;
              }
          }
          */
          //this.modules.find(module => module.id === response.module_id).lessons.push();
        }
      }
    },
    mounted() {
      this.fetchSchool();
    }
  };
  </script>
  
  <style scoped>
  /* Adicione estilos específicos aqui */
  </style>