<template>
  <div>
    <v-dialog v-model="form.dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form.isEditing ? 'Atualizar Assinatura' : 'Nova Assinatura' }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="form.isValid">
            <v-text-field
              v-if="!client_id && !form.isEditing"
              v-model="form.signature.customer_id"
              label="ID do Cliente"
              type="number"
              :rules="[form.rules.required]"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.signature.start_date"
              label="Data de Início"
              type="date"
              :rules="[form.rules.required]"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.signature.end_date"
              label="Data de Término"
              type="date"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.signature.value"
              label="Valor"
              prefix="R$"
              type="number"
              :rules="[form.rules.required]"
              required
            ></v-text-field>

            <v-select
              v-model="form.signature.cycle"
              :items="form.cycles"
              label="Ciclo de Pagamento"
              :rules="[form.rules.required]"
              required
            ></v-select>

            <v-textarea
              v-model="form.signature.description"
              label="Descrição"
              :rules="[form.rules.required]"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="form.dialog = false">Cancelar</v-btn>
          <v-btn color="green" text @click="saveSignature" :disabled="!form.isValid">
            {{ form.isEditing ? 'Atualizar' : 'Salvar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="mb-4">
      <v-card-title>
        <span class="headline">Assinaturas</span>
        <v-spacer></v-spacer>
        <v-btn v-if="client_id" color="primary" @click="addNewSignature">Adicionar Nova Assinatura</v-btn>
      </v-card-title>
      <v-data-table :items="list" :headers="signatureHeaders" hide-default-footer>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editSignature(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
      <div class="text-center">
        <v-pagination v-model="page" :length="totalPages" @input="fetchSignatures"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ['aplication', 'signatures', 'client_id'],
  data() {
    return {
      isEditing: null,
      signatureHeaders: [
        { text: "Descrição", value: "description" },
        { text: "Data de Início", value: "start_date" },
        { text: "Data de Término", value: "end_date" },
        { text: "Valor", value: "value" },
        { text: "Ciclo", value: "cycle" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      list: [],
      totalPages: 1,
      page: 1,
      form: {
        dialog: false,
        isValid: false,
        isEditing: null,
        signature: {
          id: null,
          customer_id: '',
          start_date: '',
          end_date: '',
          value: '',
          cycle: 'monthly',
          description: ''
        },
        cycles:[
          {value:"weekly", text: "Semanal"},
          {value:"biweekly", text: "Quinzenal"},
          {value:"monthly", text: "Mensal"},
          {value:"bimonthly", text: "Bimestral"},
          {value:"quarterly", text: "Trimestral"},
          {value:"semiannually", text: "Semestral"},
          {value:"yearly", text: "Anual"},
        ],
        rules: {
          required: value => !!value || 'Campo obrigatório'
        }
      }
    };
  },
  methods: {
    async post(endpoint = "/", data) {
      try {
        this.$emit('setLoading', true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "POST",
          headers: {
            'Authorization': this.aplication.authToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        });
        this.$emit('reactRequest', response.data);
        this.$emit('setLoading', false);
        return response.data;
      } catch (error) {
        this.$emit('setLoading', false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest', error.response.data);
        }
      }
    },
    async get(endpoint = "/") {
      try {
        this.$emit('setLoading',true);
        const response = await axios({
        url: this.aplication.baseUrlApi + endpoint,
        method: "GET",
        headers: {
          'Authorization': this.aplication.authToken,
        }
        });
        this.$emit('reactRequest',response.data);
        this.$emit('setLoading',false);
        return response.data;
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest',error.response.data);
        }
      }
    },
    async fetchSignatures() {
      let response;
      if (this.client_id) 
      response = await this.get(`/client/${this.client_id}signatures?page=${this.page}&getCustomer=true`);
      else
      response = await this.get(`/signatures?page=${this.page}&getCustomer=true`);
      this.list = response.items;
      this.totalPages = response.pages;
    },
    addNewSignature() {
      if (this.client_id) {
        this.form.signature.client_id = this.client_id;
      }
      this.form.signature = {
        customer_id: this.client_id || '',
        start_date: '',
        end_date: '',
        value: '',
        cycle: 'monthly',
        description: ''
      };
      this.form.isEditing = false;
      this.form.dialog = true;
    },
    editSignature(item) {
      this.form.signature = { ...item };
      this.form.isEditing = true;
      this.form.dialog = true;
    },
    async saveSignature() {
      if (!this.$refs.form.validate()) {
        alert("Verifique os dados do formulário");
        return;
      }
      let endpoint = "/signature";
      if (this.form.isEditing) endpoint += `/${this.form.signature.id}/update`;

      let response = await this.post(endpoint, this.form.signature);
      if (response.id) {
        if (!this.form.isEditing) {
          this.list.unshift(response);
        } else {
          let index = this.list.findIndex(sig => sig.id === response.id);
          if (index > -1) this.$set(this.list, index, response);
        }
        this.form.dialog = false;
        alert("Assinatura salva");
      }
    }
  },
  mounted() {
    if (this.client_id) {
      this.list = this.signatures.items;
      this.totalPages = this.signatures.pages;
    } else {
      this.signatureHeaders.unshift({ text: "Cliente", value: "customer_name" });
      this.fetchSignatures();
    }
  }
};
</script>