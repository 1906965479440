<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        
        <!-- Formulário de Login -->
        <v-card>
          <!-- <v-card-title class="text-h5 justify-center">Login</v-card-title> -->
          <v-card-text>
            <div class="text-center">
                <img class="logo" :src="aplication.logoUrl" alt="">
            </div>
            <v-form ref="loginForm" v-model="valid">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[rules.required, rules.email]"
                prepend-icon="mdi-email"
              ></v-text-field>
              <v-layout wrap>
                <v-flex>
                  <v-text-field
                    v-model="password"
                    label="Senha"
                    :type="passwordFieldType"
                    :rules="[rules.required]"
                    prepend-icon="mdi-lock"
                  ></v-text-field>
                </v-flex>
                <v-flex shrink v-if="password!=''" class="pt-3">
                  <v-btn icon @click="passwordFieldType='text'" v-if="passwordFieldType=='password'"><v-icon>mdi-eye</v-icon></v-btn>
                  <v-btn icon @click="passwordFieldType='password'" v-else><v-icon>mdi-eye-off</v-icon></v-btn>
                </v-flex>
              </v-layout>
              <v-btn block color="primary" @click="login">Entrar</v-btn>
            </v-form>
            <v-btn text @click="showForgotPassword = true">Esqueceu a senha?</v-btn>
            <v-btn text @click="showSignUp = true">Cadastre-se</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Diálogo Esqueceu a Senha -->
    <v-dialog v-model="showForgotPassword" max-width="500px">
      <v-card>
        <v-card-title>Recuperar Senha</v-card-title>
        <v-card-text>
          <v-form ref="forgotPasswordForm">
            <v-text-field
              v-model="resetEmail"
              label="Email"
              prepend-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="sendPasswordResetEmail">Enviar</v-btn>
          <v-btn text @click="showForgotPassword = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo Cadastro -->
    <v-dialog v-model="showSignUp" max-width="500px">
      <v-card>
        <v-card-title>Cadastro</v-card-title>
        <v-card-text>
          <v-form ref="signUpForm">
            <v-text-field
              v-model="signUp.name"
              label="Nome"
              prepend-icon="mdi-account"
            ></v-text-field>

            <v-text-field
              v-model="signUp.cellphone"
              label="Telefone"
              prepend-icon="mdi-phone"
            ></v-text-field>
            <v-text-field
              v-model="signUp.document"
              label="CPF ou CNPJ"
              prepend-icon="mdi-file-document-multiple-outline"
            ></v-text-field>
            <v-text-field
              v-model="signUp.birthday"
              label="Data de Nascimento"
              prepend-icon="mdi-calendar"
              type="date"
            ></v-text-field>

            <v-text-field
              v-model="signUp.email"
              label="Email"
              prepend-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-text-field
              v-model="signUp.password"
              label="Senha"
              type="password"
              prepend-icon="mdi-lock"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="signUp.confirmPassword"
              label="Confirmar Senha"
              type="password"
              prepend-icon="mdi-lock-check"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="register">Cadastrar</v-btn>
          <v-btn text @click="showSignUp = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuthPage',
  props: ['aplication'],
  data: () => ({
    email: '',
    password: '',
    passwordFieldType: 'password',
    resetEmail: '',
    signUp: {
        name: '',
        email: '',
        cellphone: '',
        document: '',
        birthday: '',
        password: '',
        confirmPassword: ''
    },
    valid: false,
    showForgotPassword: false,
    showSignUp: false,
    rules: {
      required: (value) => !!value || 'Campo obrigatório',
      email: (value) => /.+@.+\..+/.test(value) || 'E-mail inválido'
    },
  }),
  methods: {
    async post(endpoint = "/", data) {
      try {
        this.$emit('setLoading',true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "POST",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        });
        this.$emit('setLoading',false);
        this.$emit('reactRequest',response.data);
        return response.data;
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.$emit('reactRequest',error.response.data);
        }
      }
    },
    async login() {
      let response = await this.post("/app/login",{ email: this.email, password: this.password });
      if(response.user)
        this.$emit('login',response.user);
    },
    async sendPasswordResetEmail() {
      // Lógica para enviar e-mail de recuperação de senha
      let response = await this.post("/app/recoverpassword",{ email: this.resetEmail });
      /*
      if(response.message){
        alert(response.message);
      }
      */
      if(response.recover){
        this.showForgotPassword = false;
      }
    },
    async register() {
      if (!this.$refs.signUpForm.validate()) {
        alert("Formulário de cadastro incompleto!");
        return;
      }
      if(this.signUp.password !== this.signUp.confirmPassword){
        alert("As senhas informadas não são compatíveis");
        return;
      }
      let response = await this.post("/app/register",this.signUp);
      if(response.user){
        this.showSignUp=false;
        this.$emit('login',response.user);
      }
    }
  },
};
</script>

<style scoped>
.v-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.logo{
    max-height: 50px;
}
</style>