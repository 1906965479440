<template>
  <v-app>
    <v-app-bar app v-if="aplication.authToken">
      <v-toolbar-title><span v-html="toolbar.title"></span></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="!navMenu.permanent"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-overlay :value="loading" absolute class="app-loader">
      <v-progress-circular indeterminate size="64" class="app-loader"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="snackbar.active"
      :multi-line="snackbar.multiLine"
      right
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.active = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :permanent="navMenu.permanent"
      left
      v-if="aplication.authToken"
      width="250px"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <img class="logo" :src="aplication.logoUrl" alt="">
          </v-list-item-title>
          <v-list-item-subtitle>
            Plataforma de Pagamentos
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          @click="navigateTo(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span v-html="item.title"></span>
            <v-chip v-if="item.shortly" x-small class="ml-1 px-1" color="primary">Em breve</v-chip>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="aplication.suppot_url"
          :href="aplication.suppot_url"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span>Ajuda</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2 btnLogout">
          <v-btn block @click="logout()" color="secondary" outlined>
            Sair
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <template  v-if="aplication.authToken">
        <CompleteAccount v-if="completeRegister" @close="completeRegister=null" :aplication="aplication" @reactRequest="reactRequest" @setLoading="setLoading" :open="completeRegister"></CompleteAccount>
        <component :is="currentView" :data="context" :aplication="aplication" @reactRequest="reactRequest" @setLoading="setLoading" navigateTo="navigateTo"></component>
      </template>
      <AuthPage v-else :aplication="aplication" @reactRequest="reactRequest" @login="login" @setLoading="setLoading"></AuthPage>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import page from "page";
import AuthPage from './components/AuthPage.vue';
import UserAccount from './components/UserAccount.vue';
import HelloWorld from './components/HelloWorld.vue';
import ChargesPage from './components/ChargesPage.vue';
import SignaturesPage from './components/SignaturesPage.vue';
import ClientsPage from './components/ClientsPage.vue';
import MembersArea from './components/MembersArea.vue';
import CompleteAccount from './components/CompleteAccount.vue';
import ExtractPage from './components/ExtractPage.vue';

export default {
  name: 'App',
  components:{
    AuthPage, CompleteAccount
  },
  data: () => ({
    aplication: {
      baseUrlApi: null,
      logoUrl: null,
      authToken: null,
      suppot_url: null
    },
    navMenu:{
      permanent: false
    },
    toolbar:{
      title: "Dashboard"
    },
    snackbar:{
      active: false,
      text: "",
      multiLine: true
    },
    completeRegister: null,
    loading: false,
    currentView: "",
    context: null,
    drawer: false,
    menuItems: [
      { title: 'Dashboard', icon: 'mdi-home', route: '/' },
      { title: 'Minha Conta', icon: 'mdi-account', route: '/conta' },
      { title: 'Clientes', icon: 'mdi-account-group', route: '/clientes' },
      { title: 'Assinaturas', icon: 'mdi-cash-sync', route: '/assinaturas' },
      { title: 'Cobranças', icon: 'mdi-cash', route: '/cobrancas' },
      { title: 'Extrato', icon: 'mdi-cash-register', route: '/extract' },
      /*
      { title: 'Área de Membros', icon: 'mdi-account-school', route: '/areademembros' },
      { title: 'Produtos', icon: 'mdi-cube', shortly: true },
      { title: 'Afiliação', icon: 'mdi-account-supervisor', shortly: true },
      { title: 'Marketplace', icon: 'mdi-store', shortly: true },
      { title: 'Aplicativos', icon: 'mdi-web-box', shortly: true },
       */
    ],
  }),
  methods: {
    navigateTo(item) {
      if(!item.route || item.shortly){
        return;
      }
      this.toolbar.title = item.title;
      page(item.route);
      if(this.navMenu.permanent != true){
        this.drawer = false;
      }
    },
    setLoading(value){
      if(value === true){
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    login(user){
      this.authToken(user.token);
    },
    logout(){
      this.authToken(false);
    },
    authToken(value = null){
      if(value===false){
        localStorage.removeItem('authtoken');
        this.aplication.authToken = null;
        return null;
      }
      if(value){
        localStorage.setItem('authtoken', value);
        this.aplication.authToken = value;
        return;
      }
      if(this.aplication.authToken){
        return this.aplication.authToken;
      }
      this.aplication.authToken = localStorage.getItem('authtoken');
      return this.aplication.authToken;
    },
    returnError(errors){
      if(errors.type=="auth_empty" || errors.type=="invalid_auth"){
          this.authToken(false);
        }
        if(errors.message){
          alert(errors.message);
        }
    },
    reactRequest(response){
      if(response.console) console.log(response.console);
      if(response.completeregister){
        if(response.completeregister=="ok")
          this.completeRegister = null;
        else
          this.completeRegister = response.completeregister;
      }

      if(response.errors){
        this.returnError(response.errors);
        return;
      }
      if(response.message){
        alert(response.message.text);
      }
      if(response.alert){
        alert(response.alert);
      }
      if(response.notification){
        this.snackbar.text = response.notification;
        this.snackbar.active = true;
      }
    },
    async post(endpoint = "/", data) {
      try {
        this.setLoading(true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "POST",
          headers: {
            'Authorization': this.aplication.authToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        });
        this.reactRequest(response.data);
        this.setLoading(false);
        return response.data;
      } catch (error) {
        this.setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.reactRequest(error.response.data);
        }
      }
    },
    async get(endpoint = "/") {
      try {
        this.setLoading(true);
        const response = await axios({
          url: this.aplication.baseUrlApi + endpoint,
          method: "GET",
          headers: {
            'Authorization': this.aplication.authToken,
          }
        });
        this.reactRequest(response.data);
        this.setLoading(false);
        return response.data;
      } catch (error) {
        this.setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          this.reactRequest(error.response.data);
        }
      }
    }
  },
  mounted() {
    this.aplication.baseUrlApi = window.API_BASE_URL;
    this.aplication.logoUrl = window.LOGO_URL;
    this.aplication.suppot_url = (window.APP_SUPPORT_URL ?? null);
    this.showNotification = true;
    // Define a base para todas as rotas
    //page.base('/ukalaa/app');
    page.base(window.ROUTE_BASE_URL);
    
    page("/", () => {
      this.context = null;
      this.currentView = HelloWorld;
    });
    page("/conta", (context) => {
      this.context = context.params;
      this.currentView = UserAccount;
    });
    page("/cobrancas", (context) => {
      this.context = context.params;
      this.currentView = ChargesPage;
    });
    page("/assinaturas", (context) => {
      this.context = context.params;
      this.currentView = SignaturesPage;
    });
    page("/clientes", (context) => {
      this.context = context.params;
      this.currentView = ClientsPage;
    });
    page("/areademembros", (context) => {
      this.context = context.params;
      this.currentView = MembersArea;
    });
    page("/extract", (context) => {
      this.context = context.params;
      this.currentView = ExtractPage;
    });
    page();
    if(window.screen.width>=650){
      this.navMenu.permanent = true;
    }
    this.authToken();
  }
};
</script>

<style>
.bg-theme {
  background-color: #6B28FA;
}
.logo{
    max-height: 30px;
}
@media (max-width: 650px) {
  .btnLogout {
    margin-bottom: 50px;
  }
}
.app-loader {
  z-index: 302!important;
}
</style>