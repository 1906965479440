<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h2 class="headline font-weight-bold mb-3" v-html="(dash.title ?? 'Dashboard')"></h2>
        <p v-html="dash.created_at" class="text--disabled caption"></p>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn @click="visibleValues = !visibleValues" color="secondary" outlined>
          <template v-if="visibleValues"><v-icon>mdi-eyes</v-icon> Ocultar valores</template>
          <template v-else><v-icon>mdi-eyes</v-icon> Mostrar valores</template>
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row v-if="dash.balance">
      <v-col cols="12">
        <v-card class="pa-2" outlined>
          <v-card-title class="subtitle-1">Saldo</v-card-title>
          <v-card-subtitle class="text-h6"><template v-if="visibleValues">{{ dash.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</template><template v-else>********</template></v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <!-- Métricas -->
    <v-row>
      <v-col v-for="metric in dash.metrics" :key="metric.title" cols="12" md="4" sm="6">
        <v-card class="pa-2" outlined :color="(metric.bgcolor ?? null)">
          <v-card-title class="subtitle-1">{{ metric.title }}</v-card-title>
          <v-card-subtitle class="text-h6"><template v-if="visibleValues">{{ metric.value }}</template><template v-else>********</template></v-card-subtitle>
          <v-card-text class="text-caption">{{ metric.description }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Gráficos-->
    <v-row v-if="dash.charts && dash.charts.length>0">
      <v-col v-for="chart in dash.charts" :key="chart.name" cols="12" :md="(chart.collum ?? 6)">
        <v-card outlined>
          <v-card-title>{{ chart.name }}</v-card-title>
          <!-- Se chart.type=='pie' quero que o chart fique em um tamanho menor -->
          <v-container>
            <canvas :id="'chart-' + chart.id" :style="chart.type === 'pie' ? 'max-width: 300px; margin: 0 auto;' : ''"></canvas>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="dash.graphics && dash.graphics.length>0">
      <v-col v-for="graphic in dash.graphics" :key="graphic.name" cols="12" md="6">
        <v-card outlined>
          <v-card-title>{{ graphic.name }}</v-card-title>
          <v-container>
            <canvas :id="'chart-' + graphic.name"></canvas>
            <!--
              <v-sparkline
                :value="graphic.items"
                :labels="graphic.labels"
                color="green"
                padding="8"
                line-width="3"
                :type="(graphic.type || 'trend')"
              ></v-sparkline>
            -->
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- Tabelas -->
    <v-row>
      <v-col v-for="table in dash.tables" :key="table.name" cols="12">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ table.name }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default {
  props: ['aplication'],
  name: 'HelloWorld',
  data() {
    return {
      dash: {}, // Dados do dashboard recebidos da API
      visibleValues: false,
      charts: {}
    };
  },
  methods: {
    async fetchDashboardData() {
      try {
        this.$emit('setLoading',true);
        const response = await axios.get(this.aplication.baseUrlApi + '/app/dashboard', {
          headers: { Authorization: this.aplication.authToken }
        });
        
        // Set data for metrics and charts
        this.dash = response.data;
        this.$emit("reactRequest", response.data);
        this.$emit('setLoading',false);

        // Renderizar gráficos após os dados estarem carregados
        this.$nextTick(() => this.renderCharts());
      } catch (error) {
        this.$emit('setLoading',false);
        if (error.response &&error.response.data &&error.response.data.errors) {
          this.$emit("reactRequest", error.response.data);
        }
      }
    },
    renderCharts() {
      if(this.dash.charts && this.dash.charts.length>0)
        this.dash.charts.forEach((graphic) => {
          const chartId = `chart-${graphic.id}`;
          const ctx = document.getElementById(chartId).getContext('2d');
          
          // Destruir gráfico existente antes de criar um novo
          if (this.charts[chartId]) {
            this.charts[chartId].destroy();
          }
          // Criar gráfico com os dados da API
          this.charts[chartId] = new Chart(ctx, graphic);
        });
      else 
      this.dash.graphics.forEach((graphic) => {
        const chartId = `chart-${graphic.name}`;
        const ctx = document.getElementById(chartId).getContext('2d');
        
        // Destruir gráfico existente antes de criar um novo
        if (this.charts[chartId]) {
          this.charts[chartId].destroy();
        }
        
        // Criar gráfico com os dados da API
        this.charts[chartId] = new Chart(ctx, {
          type: graphic.type || 'line',
          data: {
            labels: graphic.labels,
            datasets: [{
              label: graphic.name,
              data: graphic.items,
              //backgroundColor: 'rgba(75, 192, 192, 0.2)',
              backgroundColor: graphic.colors || 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }]
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      });
    }
  },
  mounted() {
    this.fetchDashboardData();
  },
  beforeDestroy() {
    // Garantir que os gráficos sejam destruídos ao desmontar o componente
    Object.values(this.charts).forEach(chart => chart.destroy());
  }
};
</script>

<style scoped>
/* Adicione estilos específicos aqui */
</style>
